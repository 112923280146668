export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export function validateURL(url) {
  var expression =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
  var regex = new RegExp(expression)
  return url.match(regex)
}

export function validatePhoneNumber(phoneNumber) {
  const re = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
  return re.test(phoneNumber)
}

export function generateMixVariantGroups(variantGroups, oldMixVariants = []) {
  const mixVariants = []
  if (variantGroups.length > 1) {
    for (let j = 0; j < variantGroups[1].value.length; j++) {
      for (let i = 0; i < variantGroups[0].value.length; i++) {
        if (variantGroups[0].value[i] && variantGroups[1].value[j]) {
          const mix = {
            price: null,
            customer_sku: null,
            thumbnail_id: variantGroups[0].thumbnail && variantGroups[0].thumbnail[i]?.id,
          }
          const types = [
            {
              title: variantGroups[0].title,
              value: variantGroups[0].value[i],
            },
            {
              title: variantGroups[1].title,
              value: variantGroups[1].value[j],
            },
          ]
          oldMixVariants.some((item) => {
            if (
              item?.types?.[0]?.title == types?.[0]?.title &&
              item?.types?.[0]?.value == types?.[0]?.value &&
              item?.types?.[1]?.title == types?.[1]?.title &&
              item?.types?.[1]?.value == types?.[1]?.value
            ) {
              mix.price = item.price
              mix.id = item.id
              mix.customer_sku = item.customer_sku
            }
          })
          mixVariants.push({
            types,
            ...mix,
            ...(oldMixVariants?.[i + j] && {
              id: oldMixVariants?.[i + j]?.id,
            }),
          })
        }
      }
    }
  } else {
    for (let i = 0; i < variantGroups[0].value.length; i++) {
      if (variantGroups[0].value[i]) {
        const mix = {
          price: null,
          customer_sku: null,
          thumbnail_id: variantGroups[0].thumbnail && variantGroups[0].thumbnail[i]?.id,
        }
        const types = [
          {
            title: variantGroups[0].title,
            value: variantGroups[0].value[i],
          },
        ]
        oldMixVariants.some((item) => {
          if (item.types[0].title == types[0].title && item.types[0].value == types[0].value) {
            mix.price = item.price
            mix.id = item.id
            mix.customer_sku = item.customer_sku
          }
        })
        mixVariants.push({
          types,
          ...mix,
          ...(oldMixVariants?.[i] && {
            id: oldMixVariants?.[i]?.id,
          }),
        })
      }
    }
  }
  return mixVariants
}

export const convertMixGroupsToVariants = (groups) => {
  const variants = [
    {
      title: '',
      value: [],
      thumbnail: [],
    },
  ]
  groups?.forEach((item) => {
    // To Mix Groups
    item.price = item.price.cents
    item.thumbnail_id = item.thumbnail?.id
    item.thumbnail_url = item.thumbnail?.url
    // To Variants
    variants[0].title = item.types[0].title
    if (item.types[0] && !variants[0].value.includes(item.types[0].value)) {
      variants[0].value.push(item.types[0].value)
    }
    const addedThumbnail = variants[0].thumbnail.some((thumbnailItem) => {
      if (thumbnailItem?.id == item.thumbnail?.id) {
        return true
      }
    })
    if (!addedThumbnail) {
      variants[0].thumbnail.push({
        id: item.thumbnail?.id,
        url: item.thumbnail?.url,
      })
    }
    if (item.types[1]) {
      if (!variants[1]) {
        variants[1] = {
          title: '',
          value: [],
        }
      }
      variants[1].title = item.types[1].title
      if (!variants[1].value.includes(item.types[1].value)) {
        variants[1].value.push(item.types[1].value)
      }
    }
  })
  return { variants, mixGroups: groups }
}

export const toStringVariantName = (types) => {
  if (!types) {
    return '--'
  }
  const vars = []
  types.map((item) => {
    vars.push(item.value)
  })
  return vars.join(' / ')
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const downloadQR = () => {
  const canvas = document.getElementById('qrcode')
  const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
  let downloadLink = document.createElement('a')
  downloadLink.href = pngUrl
  downloadLink.download = 'qrcode.png'
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

export const setCookie = (locale) => {
  document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; path=/`
}

export const parseSafe = (str) => {
  try {
    return JSON.parse(str)
  } catch (_) {
    return null
  }
}
