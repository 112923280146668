import { faColumns } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Layout, Menu } from 'antd'
import Link from 'next/link'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from '~/i18n'
import LogoVF from '~/public/assets/jpeg/vietfish_logo.jpeg'
import { DTRACK, FOODMAP, VIETFISH } from '~/utils/constants'
import Svg from '../Svg'
// import './styles.scss'

const { Sider } = Layout
const { SubMenu } = Menu
function CustomSider({ isAdmin, abilities, roles, producer_id, t, color, platform, user }) {
  const router = useRouter()
  const [currentPath, setCurrentPath] = useState('/')

  useEffect(() => {
    setCurrentPath(router.pathname)
  }, [])

  //menu convention
  //Item: PascalCase
  //SubItem: actionPascalCase
  let menus = [
    platform === VIETFISH && {
      key: '(RegisterDepartment)',
      title: t('register-department'),
      icon: <Svg name="register-department" width={18} height={18} />,
      activeIcon: <Svg name="register-department" fill={color} width={18} height={18} />,
      href: ['/register-department'],
    },
    {
      key: '(Dashboard)',
      title: t('dashboard'),
      icon: <Svg name="home" width={18} height={18} />,
      activeIcon: <Svg name="home" width={18.81} height={18} fill={color} />,
      href: ['/dashboard'],
    },
    {
      key: '(BusinessInformation)',
      title: t('business-information'),
      icon: <Svg name="setting" width={18} height={18} />,
      activeIcon: <Svg name="setting" fill={color} width={18} height={18} />,
      href: ['/account-setup'],
    },
    {
      key: '(Product)',
      title: t('products'),
      icon: <Svg name="product" width={18} height={18} />,
      activeIcon: <Svg name="product" width={18} height={18} fill={color} />,
      submenu: [
        {
          title: t('add-product'),
          href: '/add-product',
        },
        {
          title: t('list-products'),
          href: '/products',
        },
        {
          href: '/products/[id]',
        },
      ],
    },
    {
      key: '(Service)',
      title: t('services'),
      icon: <Svg name="services" width={18} height={18} />,
      activeIcon: <Svg name="services" fill={color} width={18} height={18} />,
      href: ['/services'],
    },

    platform === DTRACK && {
      key: '(Tracking)',
      title: t('tracking'),
      icon: <Svg name="tracking" width={18} height={18} />,
      activeIcon: <Svg name="tracking" fill={color} width={18} height={18} />,
      submenu: [
        {
          title: t('list-product-batches'),
          href: '/product-batches',
        },
        {
          href: '/add-batch',
        },
        {
          href: '/product-batches/[id]',
        },
      ],
    },
    // platform === DTRACK && {
    //   key: 'sales',
    //   title: t('sales'),
    //   icon: <Svg name="sales" width={18.9} height={18} />,
    //   activeIcon: <Svg name="sales" fill={color} width={18.9} height={18} />,
    //   href: ['/sales'],
    // },
    platform === DTRACK && {
      key: '(New)',
      title: t('news'),
      icon: <Svg name="news" width={18} height={18} />,
      activeIcon: <Svg name="news" fill={color} width={18} height={18} />,
      href: ['/news', '/news/[id]', '/news/categories/[id]'],
    },

    platform === DTRACK &&
      false && {
        key: '(Faq)',
        title: t('faq'),
        icon: <Svg name="lightbulb" width={12.18} height={18} />,
        activeIcon: <Svg name="lightbulb_active" fill={color} width={12.18} height={18} />,
        href: ['/faqs'],
      },
  ]

  if (isAdmin) {
    menus = [
      user?.email !== 'hangdumucteam@dtrack.asia' && {
        key: '(Producer)',
        title: t('producers'),
        icon: <Svg name="producer" width={18} height={18} />,
        href: ['/admin/producers', '/admin/producers/[id]'],
        activeIcon: <Svg name="producer_active" fill={color} width={18} height={18} />,
      },
      platform === DTRACK && {
        key: '(PurchaseOrder)(ProducerOem)',
        title: t('oems'),
        icon: <Svg name="producer" width={18} height={18} />,
        href: ['/admin/oems', '/admin/oems/[id]'],
        activeIcon: <Svg name="producer_active" fill={color} width={18} height={18} />,
        submenu: [
          {
            title: t('list-oems'),
            href: '/admin/oems',
            key: '(readProducerOem)',
          },
          {
            title: t('list-purchase-orders'),
            href: '/admin/purchase-orders',
            key: '(readPurchaseOrder)',
          },
        ],
      },
      user?.email !== 'hangdumucteam@dtrack.asia' && {
        key: '(Product)',
        title: t('products'),
        icon: <Svg name="product" width={18} height={18} />,
        activeIcon: <Svg name="product" fill={color} width={18} height={18} />,
        href: ['/admin/products', '/admin/products/[id]'],
      },
      {
        key: '(UserRegisterService)',
        title: t('services_approval'),
        icon: <Svg name="services" />,
        href: ['/admin/services-approval', '/admin/services-approval/[id]'],
        activeIcon: <Svg name="services" fill={color} />,
      },
      platform === DTRACK && {
        key: '(Message)',
        title: t('client-messages'),
        icon: <Svg name="messages" />,
        href: ['/admin/client-messages'],
        activeIcon: <Svg name="messages" fill={color} />,
      },
      {
        key: '(SaleChannelReference)',
        title: t('sales_channels'),
        icon: <Svg name="sales_channels" width={18} height={18} />,
        href: ['/admin/sales-approval'],
        activeIcon: <Svg name="sales_channels_active" fill={color} width={18} height={18} />,
        submenu: [
          {
            title: t('producer_sales_channels'),
            href: '/admin/sales-approval',
            key: '(readSaleChannelReference)(producer)',
          },
          {
            title: t('product_sales_channels'),
            href: '/admin/product-sales-approval',
            key: '(readSaleChannelReference)(product)',
          },
        ],
      },
      {
        key: '(User)',
        title: t('users'),
        icon: <Svg name="account" width={18} height={18} />,
        activeIcon: <Svg name="account" fill={color} width={18} height={18} />,
        submenu: [
          {
            title: t('list-users'),
            href: '/admin/users',
            key: '(readUser)',
          },
          {
            title: t('add-user'),
            href: '/admin/add-user',
            key: '(createUser)',
          },
          {
            href: '/admin/users/[id]',
          },
        ],
      },
      platform === DTRACK && {
        key: '(Post)',
        title: t('news'),
        icon: <Svg name="news" width={18} height={18} />,
        activeIcon: <Svg name="news" fill={color} width={18} height={18} />,
        submenu: [
          {
            title: t('list-posts'),
            href: '/admin/posts',
            key: '(readPost)',
          },
          {
            title: t('add-post'),
            href: '/admin/add-post',
            key: '(createPost)',
          },
          {
            href: '/admin/posts/[id]',
          },
        ],
      },
      {
        key: '(Setting)',
        title: t('setting'),
        icon: <Svg name="setting" width={18} height={18} />,
        activeIcon: <Svg name="setting" fill={color} width={18} height={18} />,
        href: ['/account-setup'],
      },
      platform === DTRACK &&
        false && {
          key: '(faq)',
          title: t('faq'),
          icon: <Svg name="lightbulb" width={18} height={18} />,
          activeIcon: <Svg name="lightbulb_active" fill={color} width={18} height={18} />,
          submenu: [
            {
              title: t('list-faqs'),
              href: '/admin/faqs',
            },
            {
              title: t('add-faq'),
              href: '/admin/add-faq',
            },
            {
              href: '/admin/faqs/[id]',
            },
          ],
        },
      platform === DTRACK && {
        key: '(PageView)',
        title: t('page-views'),
        icon: <FontAwesomeIcon icon={faColumns} />,
        activeIcon: <FontAwesomeIcon icon={faColumns} color={color} />,
        submenu: [
          {
            title: t('list-page-views'),
            href: '/admin/page-views',
          },
          {
            title: t('add-page-view'),
            href: '/admin/page-views/create',
          },
          {
            href: '/admin/page-views/[id]',
          },
        ],
      },
    ]
  }

  menus = menus.filter((value) => value)

  const getSelectedSubmenu = useCallback(() => {
    const keys = {
      '/admin/add-oem': function () {
        return '(PurchaseOrder)(ProducerOem)'
      },
      '/admin/oems': function () {
        return '(PurchaseOrder)(ProducerOem)'
      },
      '/admin/oems/[id]': function () {
        return '(PurchaseOrder)(ProducerOem)'
      },
      '/admin/add-purchase-order': function () {
        return '(PurchaseOrder)(ProducerOem)'
      },
      '/admin/purchase-orders': function () {
        return '(PurchaseOrder)(ProducerOem)'
      },
      '/admin/purchase-orders/[id]': function () {
        return '(PurchaseOrder)(ProducerOem)'
      },
      '/admin/sales-approval': function () {
        return '(SaleChannelReference)'
      },
      '/admin/product-sales-approval': function () {
        return '(SaleChannelReference)'
      },
      '/admin/users': function () {
        return '(User)'
      },
      '/admin/users/[id]': function () {
        return '(User)'
      },
      '/admin/add-user': function () {
        return '(User)'
      },
      '/admin/page-views': function () {
        return '(PageView)'
      },
      '/admin/page-views/[id]': function () {
        return '(PageView)'
      },
      '/admin/page-views/create': function () {
        return '(PageView)'
      },
      '/admin/add-product': function () {
        return '(Product)'
      },
      '/admin/products': function () {
        return '(Product)'
      },
      '/admin/products/[id]': function () {
        return '(Product)'
      },
      '/admin/add-batch': function () {
        return '(Tracking)'
      },
      '/admin/product-batches': function () {
        return '(Tracking)'
      },
      '/admin/product-batches/[id]': function () {
        return '(Tracking)'
      },
      '/admin/posts': function () {
        return '(Post)'
      },
      '/admin/posts/[id]': function () {
        return '(Post)'
      },
      '/admin/add-post': function () {
        return '(Post)'
      },
      default: function () {
        return '(Dashboard)'
      },
    }
    return (keys[router.pathname] || keys[`/admin${router.pathname}`] || keys['default'])()
  }, [router.pathname])

  const renderLogo = () => {
    const logos = {
      [DTRACK]: <Svg name="logo" height={50} width={167} />,
      [VIETFISH]: <Image src={LogoVF} alt="logo" width={64} height={64} />,
      [FOODMAP]: (
        <div style={{ padding: '4px 0' }}>
          <Svg name="insight-sider-logo" height={60} width={116} />
        </div>
      ),
    }

    return logos?.[platform] || <></>
  }

  const checkShowTab = (key, sub = false) => {
    const values = [...(abilities || []), { actions: ['manage'], subject: ['Setting'] }]
    return (
      roles?.includes('admin') ||
      values.some(
        (abl) =>
          key?.includes(`(${sub ? abl.actions?.[0] : ''}${abl.subject?.[0]})`) ||
          (key?.includes(`${abl.subject?.[0]})`) && abl.actions?.[0] === 'manage'),
      )
    )
  }

  const renderMenus = () => {
    return menus.map((item) => {
      const showTab = checkShowTab(item.key)

      if (!isAdmin && !producer_id && ['sales'].includes(item.key)) {
        return null
      }

      if (isAdmin && !showTab) {
        return null
      }

      const icon = item.submenu?.some((subItem) => subItem.href == currentPath)
        ? item.activeIcon
        : item.icon

      return (
        <React.Fragment key={item.key}>
          {item?.submenu ? (
            <SubMenu
              icon={icon}
              title={item.title}
              key={item.key}
              className={item.key === getSelectedSubmenu() ? 'selected-submenu-item' : ''}
            >
              {item.submenu
                ?.filter((i) => !!i?.title)
                .map((subItem) => {
                  let showSubItem = isAdmin ? checkShowTab(subItem.key, true) : true
                  if (!showSubItem) {
                    return null
                  } else {
                    return (
                      <Menu.Item key={`${subItem.href}`} onClick={() => router.push(subItem.href)}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            className={
                              currentPath === subItem.href
                                ? 'submenu-circle-active'
                                : 'submenu-circle'
                            }
                          />
                          <span style={{ color: 'var(--cl16)' }}>{subItem.title}</span>
                        </div>
                      </Menu.Item>
                    )
                  }
                })}
            </SubMenu>
          ) : (
            <Menu.Item
              className={item.href?.includes(currentPath) ? 'selected-menu-item' : ''}
              icon={icon}
              key={item.key}
              onClick={() => router.push(item.href[0])}
            >
              <span style={{ color: 'var(--cl16)' }}>{item.title}</span>
            </Menu.Item>
          )}
        </React.Fragment>
      )
    })
  }

  return (
    <Sider>
      <Link href="/">
        <div className="logo-wrapper" style={{ padding: '0.25rem 1.75rem' }}>
          {renderLogo()}
        </div>
      </Link>
      <Menu mode="inline" defaultOpenKeys={[getSelectedSubmenu()]}>
        {renderMenus()}
      </Menu>
    </Sider>
  )
}

const mapStateToProps = (state) => ({
  producer: state.producer.data,
  user: state.user,
  isAdmin: state.user.roles?.length > 0,
  producer_id: state.user?.producer_id,
  isRegisteredFoodmap: state.user?.registed_services?.includes('ban-hang-da-kenh-cung-voi-foodmap'),
  color: state.layout?.platform?.primaryColor || '#029147',
  platform: state.layout.platform?.platform,
  isOldUser: state.user?.isOldUser,
  roles: state.user?.roles,
  abilities: state.user?.abilities,
})

CustomSider.getInitialProps = async () => ({
  namespacesRequired: ['defaultLayout'],
})

export default compose(
  withTranslation('defaultLayout'),
  connect(mapStateToProps, null),
)(CustomSider)
